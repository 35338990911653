import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./Modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    //data: { preload: true }
  },
  {
    path: 'company/:companyId/dashboard',
    loadChildren: () => import('./Modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { preload: true }
  },
  {
    path: 'company/:companyId/settings',
    loadChildren: () => import('./Modules/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'company/:companyId/contacts',
    loadChildren: () => import('./Modules/contacts/contacts.module').then(m => m.ContactsModule),
  },
  {
    path: 'company/:companyId/offices',
    loadChildren: () => import('./Modules/offices/offices.module').then(m => m.OfficesModule),
  },
  {
    path: 'company/:companyId/documents',
    loadChildren: () => import('./Modules/documents/documents.module').then(m => m.DocumentsModule),
  },
  {
    path: 'company/:companyId/entity/:entityId/dashboard',
    loadChildren: () => import('./Modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    //data: { preload: true }
  },
  {
    path: 'company/:companyId/entity/:entityId/settings',
    loadChildren: () => import('./Modules/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'company/:companyId/entity/:entityId/contacts',
    loadChildren: () => import('./Modules/contacts/contacts.module').then(m => m.ContactsModule),
  },
  {
    path: 'company/:companyId/entity/:entityId/offices',
    loadChildren: () => import('./Modules/offices/offices.module').then(m => m.OfficesModule),
  },
  {
    path: 'company/:companyId/entity/:entityId/documents',
    loadChildren: () => import('./Modules/documents/documents.module').then(m => m.DocumentsModule),
  },
  {
    path: 'documentdownload/:documentId/:triggerName',
    loadChildren: () => import('./Modules/documentdownload/documentdownload.module').then(m => m.DocumentdownloadModule),
  },
  {
    path: 'documentdownloadbyalternateid/:documentdownloadbyalternateid/:triggerName',
    loadChildren: () => import('./Modules/documentdownload/documentdownload.module').then(m => m.DocumentdownloadModule),
  },
  { 
    path: 'impersonate/stop', 
    loadChildren: () => import('./Modules/impersonate/impersonate.module').then(m => m.ImpersonateModule),
  },
  {
    path: "**",
    redirectTo: ""
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

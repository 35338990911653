import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MaterialExampleModule } from 'src/app/material.module';
//import { SharedComponentModule } from 'vistra-shared-component';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    MaterialExampleModule,
    //SharedComponentModule
  ],
  exports: [
    HeaderComponent
  ],
})
export class HeaderModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _navigationLinkListSubject: any;
  private _userState: any; 
  private _organisationDetail: any;
  private _routeParam_entityId:any;
  private _routeParam_companyId:any;
  private _all_apps:any;
  private _company_details:any;
  private _hasDocumentAccess: boolean;

  constructor() { 
    this._userState = null;
    this._organisationDetail = null;
    this._routeParam_companyId = null;
    this._routeParam_entityId = null;
    this._all_apps = null;
    this._company_details = null;
    this._navigationLinkListSubject = null;
    this._hasDocumentAccess = false;
  }

  userState$ = new BehaviorSubject<any>(null);
  organisationDetail$ = new BehaviorSubject<any>(null);

  get userState() {
    return this._userState;
  }

  get organisationDetail() {
    return this._organisationDetail;
  }

  get routeParam_companyId() {
    return this._routeParam_companyId;
  }

  get routeParam_entityId() {
    return this._routeParam_entityId;
  }  

  get all_apps() {
    return this._all_apps;
  }  

  get company_details() {
    return this._company_details;
  }  

  get navigationLinkListSubject() {
    return this._navigationLinkListSubject;
  }  

  set navigationLinkListSubject(value) {
    this._navigationLinkListSubject = value;
  }  

  set company_details(value) {
    this._company_details = value;
  }  

  set all_apps(value) {
    this._all_apps = value;
  }  

  set userState(value) {
    this._userState = value;
  }

  set organisationDetail(value) {
    this._organisationDetail = value;
  }

  set routeParam_companyId(value) {
    this._routeParam_companyId = value;
  }

  set routeParam_entityId(value) {
    this._routeParam_entityId = value;
  }  

  get hasDocumentAccess() {
    return this._hasDocumentAccess;
  }  

  set hasDocumentAccess(value) {
    this._hasDocumentAccess = value;
  } 
}
import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocumentPublishManagerService } from './Services/document-publish-manager.service';
import { NotifierService } from 'hsp-portal-web-core-15';
import { CommonService } from './Services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  showLoad: boolean = true;
  serviceName = 'Vistra Managed Compliance Services';
  // items = ['Pizza', 'Pasta', 'Parmesan'];
  // userList: any = [];
  myButtonClicked(text: any) {
    console.log(text);
  }
  // emailPopupData: any = {
  //   isOpen: false,
  //   senderEmailList: [],
  //   senderEmail: '',
  //   recipient: [],
  //   recipientCC: [],
  //   recipientBCC: [],
  //   internalDistribution: [],
  //   entityDistributionEmailList: [],
  //   companyId: null,
  //   entityId: null,
  //   value: ``
  // };
  // senderEmailList: any=[];
  // senderEmail = '';
  // recipient: any = [];
  // recipientCC: any = [];
  // recipientBCC: any = [];
  // internalDistribution: any = [];
  // entityDistributionEmailList: any = [];
  // companyId: any = null;
  // entityId: any = null;
  // value: any = ``;
  // subject:string='';

  constructor(public http: HttpClient, private notifierService: NotifierService, public commonService: CommonService, private documentPublishManagerService: DocumentPublishManagerService) {
    // this.commonService.sendEmailPopupOpen$.subscribe(data => {
    //   if (data){
    //     this.emailPopupData = data;
    //     this.senderEmailList=data.senderEmailList;
    //     this.entityDistributionEmailList = data.entityDistributionEmailList;
    //   }
    //   console.log(data);
    // });
  }
  ngOnInit(): void {
    //this.initializationService.initializesEvent.subscribe(value => {
    //skipLocationChange:true means dont update the url to / when navigating       
    //});
    this.showLoad = false;
  }
  // public requestAutocompleteItems = (text: string): Observable<any> => {
  //   let token: any;
  //   if (sessionStorage.getItem('TokenManager.token_Osc_env01')) {
  //     token = sessionStorage.getItem("TokenManager.token_Osc_env01");
  //   } else {
  //     token = sessionStorage.getItem("TokenManager.token_Osc_env01");
  //   }
  //   let accessstoken = JSON.parse(token)
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json;charset=UTF-8',
  //     'Authorization': `Bearer ${accessstoken.access_token}`,
  //     'authority': window.location.host,
  //     'path': '/env01/services/DocumentPublishManager/GetUserList'
  //   });
  //   const requestOptions = { headers: headers };
  //   let body = {
  //     "CustomerId": 595, "SearchTerm": text,
  //     "CpiTrigger": "Osc.PublishDocumentsPopup.CcRecipientList.GetOnSearch"
  //   };

  //   const url = `/env01/services/DocumentPublishManager/GetUserList`;
  //   return this.http
  //     .post<any>(url, body, requestOptions)
  //     .pipe(map(items => {
  //       if (items?.userList) {
  //         console.log(items.userList)

  //         items.userList.forEach((item) => {
  //           item.displayName = item.firstName + '-' + item.lastName;
  //         });
  //         return items.userList
  //       }
  //     }));

  // }

  // closeEmailPopup() {
  //   this.emailPopupData.isOpen = false;
  //   this.commonService.sendEmailPopupClose$.next(this.emailPopupData);
  // }

  // sendEmail() {
  //   this.commonService.sendEmailPopupClose$.next(this.emailPopupData);

  // }

}

<div role="main" *ngIf=!showLoad>
  <div class="wrapper mainbodyBlock">
    <!-- <tag-input color="primary" placeholder="'Enter first or last name of the recipient'" [onTextChangeDebounce]="500"
      [(ngModel)]="userList" [secondaryPlaceholder]="'Search Documents Using Barcode'" [onlyFromAutocomplete]="true">
      <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [minimumTextLength]="1"
        [identifyBy]="'personId'" [displayBy]="'displayName'">
        <ng-template let-item="item" let-index="index">
          {{ item.firstName }}-{{ item.lastName }}
        </ng-template>
      </tag-input-dropdown>
    </tag-input>
    <pre>{{userList|json}}</pre> -->
    <!-- <div>
      <label>Internal Distribution</label>
      <tag-input [(ngModel)]="internalDistribution" [onlyFromAutocomplete]="true">
        <tag-input-dropdown [showDropdownIfEmpty]="true" [dynamicUpdate]="false" [focusFirstElement]="true"
          [identifyBy]="'emailAddress'" [displayBy]="'emailAddress'"
          [autocompleteItems]="entityDistributionEmailList">
          <ng-template let-item="item" let-index="index">
            {{ item.emailAddress }}
          </ng-template>
        </tag-input-dropdown>
      </tag-input>
      <pre>{{internalDistribution|json}}</pre>

    </div> -->
    <router-outlet></router-outlet>
    <!-- <div class="mainbody" *ngIf="emailPopupData.isOpen">
      <div class="modal">
        <div class="modal-content">
          <h2>Email Documents</h2>
          <div class="formcontent">
            <div class="left-side">
              <div class="form-group">
                <label for="alias">Your Alias</label>
                <kendo-dropdownlist [data]="senderEmailList" [(ngModel)]="senderEmail" textField="emailAddress"
                  valueField="emailAddress">
                </kendo-dropdownlist>
              </div>
              <div class="form-group">
                <label >Recipient To</label>
                <tag-input color="primary" placeholder="'Enter first or last name of the recipient'"
                [onTextChangeDebounce]="500" [(ngModel)]="recipient"
                [secondaryPlaceholder]="'Search Documents Using Barcode'" [onlyFromAutocomplete]="true">
                <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [minimumTextLength]="1"
                  [identifyBy]="'personId'" [displayBy]="'displayName'">
                  <ng-template let-item="item" let-index="index">
                    {{ item.firstName }}-{{ item.lastName }}
                  </ng-template>
                </tag-input-dropdown>
              </tag-input>
              </div>
              <div class="form-group">
                <label >Recipient CC</label>
                <tag-input color="primary" placeholder="'Enter first or last name of the recipient'"
                [onTextChangeDebounce]="500" [(ngModel)]="recipientCC"
                [secondaryPlaceholder]="'Search Documents Using Barcode'" [onlyFromAutocomplete]="true">
                <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [minimumTextLength]="1"
                  [identifyBy]="'personId'" [displayBy]="'displayName'">
                  <ng-template let-item="item" let-index="index">
                    {{ item.firstName }}-{{ item.lastName }}
                  </ng-template>
                </tag-input-dropdown>
              </tag-input>
              </div>
              <div class="form-group">
                <label >Recipient BCC</label>
                <tag-input color="primary" placeholder="'Enter first or last name of the recipient'"
                [onTextChangeDebounce]="500" [(ngModel)]="recipientBCC"
                [secondaryPlaceholder]="'Search Documents Using Barcode'" [onlyFromAutocomplete]="true">
                <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [minimumTextLength]="1"
                  [identifyBy]="'personId'" [displayBy]="'displayName'">
                  <ng-template let-item="item" let-index="index">
                    {{ item.firstName }}-{{ item.lastName }}
                  </ng-template>
                </tag-input-dropdown>
              </tag-input>
              </div>
              <div class="form-group">
                <label>Internal Distribution</label>
                <tag-input [(ngModel)]="internalDistribution" [onlyFromAutocomplete]="true">
                  <tag-input-dropdown [showDropdownIfEmpty]="true" [dynamicUpdate]="false" [focusFirstElement]="true"
                    [identifyBy]="'emailAddress'" [displayBy]="'emailAddress'"
                    [autocompleteItems]="entityDistributionEmailList">
                    <ng-template let-item="item" let-index="index">
                      {{ item.emailAddress }}
                    </ng-template>
                  </tag-input-dropdown>
                </tag-input>
              </div>
              <div class="form-group">
                <label for="subject">Subject</label>
                <input type="text" id="subject" name="subject" [(ngModel)]="subject">
              </div>
              <div class="form-group documents">
                <span>2 Documents Selected</span>
                <a href="#">Select Documents</a>
              </div>
            </div>
            <div class="right-side">
              <div class="form-group">
                <label for="message">Message</label>
                <kendo-editor [value]="value" style="height: 450px;">
                  <kendo-toolbar>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                    <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                    <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                  </kendo-toolbar>
                </kendo-editor>
              </div>
            </div>
          </div>
          <div class="form-actions">
            <button type="button" class="btn-cancel" (click)="closeEmailPopup">Cancel</button>
            <button type="submit" class="btn-send">Send Email</button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <kendo-dialog title="Send Email" [ngClass]="{hideemailpopup:!emailPopupData.isOpen,displayemailpopup:emailPopupData.isOpen}" (close)="closeEmailPopup()" [minWidth]="500"
      [width]="768">
      <div class="options-wrapper">
        <div class="row">
          <div class="email-form">
            <div class="form-group">
              <label>Your Alias</label>
              <kendo-dropdownlist [data]="senderEmailList" textField="emailAddress"
                valueField="emailAddress" [(ngModel)]="senderEmail">
              </kendo-dropdownlist>
            </div>
            <div class="form-group">
              <label>Recipient To</label>
              <tag-input color="primary" placeholder="'Enter first or last name of the recipient'"
                [onTextChangeDebounce]="500" [(ngModel)]="recipient"
                [secondaryPlaceholder]="'Search Documents Using Barcode'" [onlyFromAutocomplete]="true">
                <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [minimumTextLength]="1"
                  [identifyBy]="'personId'" [displayBy]="'displayName'">
                  <ng-template let-item="item" let-index="index">
                    {{ item.firstName }}-{{ item.lastName }}
                  </ng-template>
                </tag-input-dropdown>
              </tag-input>
              <div class="cc-bcc">
                <div class="form-group">
                  <label >Recipient CC</label>
                  <tag-input color="primary" placeholder="'Enter first or last name of the recipient'"
                    [onTextChangeDebounce]="500" [(ngModel)]="recipientCC"
                    [secondaryPlaceholder]="'Search Documents Using Barcode'" [onlyFromAutocomplete]="true">
                    <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [minimumTextLength]="1"
                      [identifyBy]="'personId'" [displayBy]="'displayName'">
                      <ng-template let-item="item" let-index="index">
                        {{ item.firstName }}-{{ item.lastName }}
                      </ng-template>
                    </tag-input-dropdown>
                  </tag-input>
                </div>
                <div class="form-group">
                  <label >Recipient BCC</label>
                  <tag-input color="primary" placeholder="'Enter first or last name of the recipient'"
                    [onTextChangeDebounce]="500" [(ngModel)]="recipientBCC"
                    [secondaryPlaceholder]="'Search Documents Using Barcode'" [onlyFromAutocomplete]="true">
                    <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems" [minimumTextLength]="1"
                      [identifyBy]="'personId'" [displayBy]="'displayName'">
                      <ng-template let-item="item" let-index="index">
                        {{ item.firstName }}-{{ item.lastName }}
                      </ng-template>
                    </tag-input-dropdown>
                  </tag-input>
                </div>
                <div>
                  <label>Internal Distribution</label>
                  <tag-input [(ngModel)]="internalDistribution" [onlyFromAutocomplete]="true">
                    <tag-input-dropdown [showDropdownIfEmpty]="true" [dynamicUpdate]="false" [focusFirstElement]="true"
                      [identifyBy]="'emailAddress'" [displayBy]="'emailAddress'"
                      [autocompleteItems]="entityDistributionEmailList">
                      <ng-template let-item="item" let-index="index">
                        {{ item.emailAddress }}
                      </ng-template>
                    </tag-input-dropdown>
                  </tag-input>
                </div>
                <pre>{{entityDistributionEmailList|json}}</pre>
              </div>
            </div>
            <div class="form-group">
              <label for="subject">Subject</label>
              <input type="text" id="subject" name="subject">
            </div>
            <div class="form-group documents-group">
              <span>2 Documents Selected</span>
              <button type="button" class="select-documents">Select Documents</button>
            </div>
          </div>
          <div class="email-body">
            <kendo-editor [value]="value" style="height: 450px;">
              <kendo-toolbar>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
              </kendo-toolbar>
            </kendo-editor>
          </div>
        </div>
        <kendo-dialog-actions>
          <button kendoButton>No</button>

          <button kendoButton themeColor="primary" (click)="sendEmail()">
            Yes
          </button>
        </kendo-dialog-actions>

      </div>
    </kendo-dialog> -->
  </div>
</div>


<!-- <lib-services-counter [dataList]=dataList
 [serviceName]=serviceName></lib-services-counter> -->

<!-- <lib-default-grid [columnData]=columnData [gridData]=gridData [sortData]="true" 
 [filterData]="false" (buttonClick)="myButtonClicked($event)"></lib-default-grid>
  -->
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderModule } from '../header/header.module';
import { SidebarModule } from '../sidebar/sidebar.module';
import { MainbodyComponent } from './mainbody/mainbody.component';

@NgModule({
  declarations: [MainbodyComponent],
  exports: [MainbodyComponent],
  imports: [CommonModule, SidebarModule,HeaderModule]
})

export class MainbodyModule { }

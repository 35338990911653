import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import {MaterialExampleModule} from '../../material.module';
import {MatNativeDateModule} from '@angular/material/core'
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    SidebarComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatNativeDateModule,
    MaterialExampleModule,    
  ],
  exports:[SidebarComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA]

})
export class SidebarModule { }

import { Injectable } from '@angular/core';
// import { SecureHttpService } from 'hsp-portal-web-core-15';
import { DocumentPublishManager } from '../Manager/documentPublishManager';

@Injectable({
  providedIn: 'root'
})
export class DocumentPublishManagerService {

  constructor(private documenPublishManager : DocumentPublishManager) {}

  getUserList(requestObj: any): Promise<any> {
    return this.documenPublishManager._operation['getUserList'](requestObj);
  }  
  getSenderEmailOptionList(requestObj: any): Promise<any> {
    return this.documenPublishManager._operation['getSenderEmailOptionList'](requestObj);
  }
  getCustomerEntityDistributionListEmailList(requestObj: any): Promise<any> {
    return this.documenPublishManager._operation['getCustomerEntityDistributionListEmailList'](requestObj);
  }
  sendEmail(requestObj: any): Promise<any> {
    return this.documenPublishManager._operation['sendEmail'](requestObj);
  } 
}

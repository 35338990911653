import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainbodyModule } from './Modules/mainbody/mainbody.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
//import { MapModule } from '@progress/kendo-angular-map';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
// import { CommonModule } from '@angular/common';
// import { HspPortalWebCoreNewModule } from 'hsp-portal-web-core-15';
import { ToastrModule } from 'ngx-toastr';
import { TagInputModule } from 'ngx-chips';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { HttpClientModule } from '@angular/common/http';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { EditorModule } from "@progress/kendo-angular-editor";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MainbodyModule,
    GridModule,
    ChartsModule,
    ScrollViewModule,
    //MapModule,
    TooltipsModule,
    MultiSelectModule,
    HttpClientModule,
    // HspPortalWebCoreNewModule, 
    //SharedComponentsModule,
    //AutocompleteLibModule
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right'
    }),
    TagInputModule,
    DialogsModule,
    ButtonsModule,
    DropDownsModule,
    EditorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

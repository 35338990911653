import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceFactory } from 'hsp-portal-web-core-15';


@Injectable({
  providedIn: 'root'
})
export class DocumentPublishManager {
  _operation;
  constructor(private _serviceFactory: ServiceFactory) {
    this._operation = this._serviceFactory.create('DocumentPublishManager', [
      'getUserList',
      'getSenderEmailOptionList',
      'getCustomerEntityDistributionListEmailList',
      'sendEmail'
    ]);

  }


}
